<template>
  <Tab :list="tabList" :current="1" />
  <div class="flex-1 fff flex-col">
    <van-nav-bar
      title="冷链溯源信息"
      left-text="返回"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 分页数据 -->
    <template v-for="detail in list">
      <van-row class="row">
        <van-col span="12" class="col">
          <span class="label">经营户：</span> {{ detail.dealerUser }}
        </van-col>
        <van-col span="12" class="col">
          <span class="label">经营种类：</span> {{ detail.stockType }}
        </van-col>
        <van-col span="12" class="col">
          <span class="label">摊位号：</span> {{ detail.stallName }}
        </van-col>
        <van-col span="12" class="col">
          <span class="label">联系电话：</span> {{ detail.customerTelephone }}
        </van-col>
        <van-col span="12" class="col">
          <span class="label">索证张数：</span> 
          {{ (detail.sampleSourceAcid ? 1 : 0) + (detail.sampleSourceClearance ? 1 : 0) + (detail.sampleSourceDisinfect ? 1 : 0) + (detail.sampleSourceQuarantine ? 1 : 0)}}
        </van-col>
        <van-col span="12" class="col">
          <span class="label">信用评分：</span> {{ detail.creditRank || '-' }}分
        </van-col>
      </van-row>
      <van-row class="row flex-1 imgs">
        <van-col span="10" offset="1" class="col flex-col">
          <img
            class="flex-1"
            :src="detail.sampleSourceQuarantine"
            @click="() => showImg(detail.sampleSourceQuarantine)"
          />
          <span>海关检疫证明</span>
        </van-col>
        <van-col span="10" offset="2" class="col flex-col">
          <img
            class="flex-1"
            :src="detail.sampleSourceClearance"
            @click="() => showImg(detail.sampleSourceClearance)"
          />
          <span>通关证明</span>
        </van-col>
        <van-col span="10" offset="1" class="col flex-col">
          <img
            class="flex-1"
            :src="detail.sampleSourceDisinfect"
            @click="() => showImg(detail.sampleSourceDisinfect)"
          />
          <span>消毒证明</span>
        </van-col>
        <van-col span="10" offset="2" class="col flex-col">
          <img
            class="flex-1"
            :src="detail.sampleSourceAcid"
            @click="() => showImg(detail.sampleSourceAcid)"
          />
          <span>核酸检测报告</span>
        </van-col>
      </van-row>
    </template>
    <div v-if="allData.length <= 0" class="noData">暂无数据</div>
    <!-- 分页控件 -->
    <van-pagination 
      v-model="currentPage" 
      mode="simple"
      @change="handleCurrentChange"
      :total-items="allData.length"
      items-per-page='1'
      v-if="allData.length > 0"
    >
      <template #prev-text>
        <van-icon name="arrow-left" />
      </template>
      <template #next-text>
        <van-icon name="arrow" />
      </template>
    </van-pagination>
  </div>
</template>

<script>
import { traceabilityTabList } from '@/utils/tab'
import { useRouter, useRoute } from 'vue-router'
import { reactive, ref } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
import { ImagePreview } from 'vant'
import { getTrace as getDetail } from '@/api/traceability'

export default {
  components: {
    Tab,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const id = route.query.id
    const onClickLeft = () => {
      router.back()
    }

    const tabList = reactive(traceabilityTabList)

    const showImg = (img) => {
      ImagePreview([img])
    }

    let list = ref([])
    let allData = ref([])
    let currentPage = ref(1)
    // 获取数据
    const init = () => {
      getDetail(id).then((res) => {
        allData.value = res.result
        handleCurrentChange(1)
      })
    }
    init()
    function paginate(array, page_size, page_number) {
      return allData.value.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const handleCurrentChange = (val) => {
      list.value = paginate(list, 1, val)
    }

    return {
      onClickLeft,
      tabList,
      showImg,
      list,
      allData,
      currentPage,
      handleCurrentChange
    }
  },
}
</script>

<style lang="less" scoped>
.row {
  padding: 12px;
  .col {
    display: flex;
    align-items: center;
    color: #666;
    line-height: 24px;
  }
  .label {
    width: 90px;
    text-align: right;
    color: #333;
  }
}
.detail {
  padding: 16px;
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
  .time {
    text-align: center;
    color: #666;
    font-size: 14px;
  }
  .content {
    border-top: 1px solid #e5e5e5;
  }
}

.imgs {
  img {
    width: 100%;
  }
  span {
    line-height: 40px;
  }
}
</style>
